const IconClose = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 17" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.99972 7.6824L11.5283 4.15381L12.4711 5.09662L8.94253 8.62521L12.4711 12.1538L11.5283 13.0966L7.99972 9.56802L4.47113 13.0966L3.52832 12.1538L7.05692 8.62521L3.52832 5.09662L4.47113 4.15381L7.99972 7.6824Z"
            fill="currentColor"
        />
    </svg>
);

export default IconClose;
